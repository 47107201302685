import React from "react";
import Checkbox from "../checkbox/Checkbox";
import ConCheckbox from "../checkbox/ConCheckbox";
import PerCheckbox from "../checkbox/PerCheckbox";
function Metrics({ data, check, change }) {
  return (
    <>
      <div className="tab-pane fade" id="metrics" role="tabpanel">
        <div className="w-100 d-flex justify-content-between d-md-flex align-items-center mb-5">
          <p className="mb-0">Metrics show up as columns of table data.</p>
          <button
            href="#"
            className="text-capitalize btn btn-light-primary btn-sm px-4"
          >
            metrics definitions
            <span className="svg-icon svg-icon-primary svg-icon-3 me-0 ms-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000) "
                    x="7.5"
                    y="7.5"
                    width={2}
                    height={9}
                    rx={1}
                  />
                  <path
                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>

        <div
          className="accordion accordion-icon-toggle mb-5 border-bottom pb-5"
          id="agents-accordion"
        >
          <div
            className="accordion-header d-flex"
            data-bs-toggle="collapse"
            data-bs-target="#agents-accordion-target"
          >
            <span className="accordion-icon">
              <span className="svg-icon svg-icon-4 me-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                      x="7.5"
                      y="7.5"
                      width={2}
                      height={9}
                      rx={1}
                    />
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                    />
                  </g>
                </svg>
              </span>
            </span>
            <h3 className="fs-4 text-dark fw-bold mb-0 ms-2 text-capitalize fw-bolder">
              agents
            </h3>
          </div>

          <div
            id="agents-accordion-target"
            className="fs-6 collapse show ps-7 pt-6"
            data-bs-parent="#agents-accordion"
          >
            <ul className="list-unstyled d-flex flex-wrap">
              <Checkbox
                name="check1"
                check={check}
                value={data.check1}
                time="ACW"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check2"
                check={check}
                value={data.check2}
                time="Available"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check3"
                check={check}
                value={data.check3}
                time="Error"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check4"
                check={check}
                value={data.check4}
                time="NPT"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check5"
                check={check}
                value={data.check5}
                time="On Contact"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check6"
                check={check}
                value={data.check6}
                time="Online"
                cl="col-md-4 col-12 mb-4"
              />
              <Checkbox
                name="check7"
                check={check}
                value={data.check7}
                time="Staffed"
                cl="col-md-4 col-12 mb-4"
              />
            </ul>
          </div>
        </div>

        <div
          className="accordion accordion-icon-toggle mb-5 border-bottom pb-5"
          id="contacts-accordion"
        >
          <div
            className="accordion-header d-flex collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#contacts-accordion-target"
          >
            <span className="accordion-icon">
              <span className="svg-icon svg-icon-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                      x="7.5"
                      y="7.5"
                      width={2}
                      height={9}
                      rx={1}
                    />
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                    />
                  </g>
                </svg>
              </span>
            </span>
            <h3 className="fs-4 text-dark fw-bold mb-0 ms-2 text-capitalize fw-bolder">
              contacts
            </h3>
          </div>
          <div
            id="contacts-accordion-target"
            className="fs-6 collapse ps-7 pt-6"
            data-bs-parent="#contacts-accordion"
          >
            <ul className="list-unstyled d-flex flex-wrap">
              <Checkbox
                name="contact1"
                check={check}
                value={data.contact1}
                time="Active"
                cl="col-md-4 col-12"
              />
              <Checkbox
                name="contact2"
                check={check}
                value={data.contact2}
                time=" Availability"
                cl="col-md-4 col-12"
              />
            </ul>
          </div>
        </div>

        <div
          className="accordion accordion-icon-toggle mb-5 border-bottom pb-5"
          id="contact-service-accordion"
        >
          <div
            className="accordion-header d-flex collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#contact-service-accordion-target"
          >
            <span className="accordion-icon">
              <span className="svg-icon svg-icon-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                      x="7.5"
                      y="7.5"
                      width={2}
                      height={9}
                      rx={1}
                    />
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                    />
                  </g>
                </svg>
              </span>
            </span>
            <h3 className="fs-4 text-dark fw-bold mb-0 ms-2 text-capitalize fw-bolder">
              contact service level
            </h3>
          </div>
          <div
            id="contact-service-accordion-target"
            className="fs-6 collapse ps-7 pt-2"
            data-bs-parent="#contact-service-accordion"
          >
            <div className="accordion mt-2 mb-4" id="add-custom-SL">
              <div className="accordion-item">
                <h2 className="accordion-header" id="add-SL">
                  <button
                    className="accordion-button fs-5 fw-bold py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#nested"
                    aria-expanded="false"
                    aria-controls="nested"
                  >
                    <span className="svg-icon svg-icon-primary me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect
                            fill="#000000"
                            x={4}
                            y={11}
                            width={16}
                            height={2}
                            rx={1}
                          />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                            x={4}
                            y={11}
                            width={16}
                            height={2}
                            rx={1}
                          />
                        </g>
                      </svg>
                    </span>
                    Add Custom SL
                  </button>
                </h2>
                <div
                  id="nested"
                  className="accordion-collapse collapse"
                  aria-labelledby="add-SL"
                  data-bs-parent="#add-custom-SL"
                >
                  <div className="accordion-body">
                    <div className="row align-items-end">
                      <div className="col-md-5 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="length"
                            className="w-100 me-3 text-capitalize fw-bolder mb-1"
                          >
                            length of time
                          </label>
                          <input
                            className="form-control btn-sm"
                            type="text"
                            id="length-of-time"
                            onChange={change}
                            name="length_of_time"
                            value={data.length_of_time}
                          />
                        </div>
                      </div>
                      <div className="col-md-5 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="unit"
                            className="w-100 me-3 text-capitalize fw-bolder mb-1"
                          >
                            Unit
                          </label>
                          <select
                            className="form-select btn-sm"
                            id="unit"
                            onChange={change}
                          >
                            <option>seconds</option>
                            <option>minutes</option>
                            <option>hours</option>
                            <option>days</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 col-12">
                        <button
                          href="#"
                          className="text-capitalize btn btn-light-primary btn-sm px-lg-6 px-4 fw-normal"
                        >
                          add
                        </button>
                      </div>
                      <div className="col-12 mt-2">
                        <small>
                          <em>Limit between 1 second and 7 days.</em>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul className="list-unstyled d-flex flex-wrap">
              <ConCheckbox check={check} data={data} />
            </ul>
          </div>
        </div>

        <div
          className="accordion accordion-icon-toggle"
          id="performance-accordian"
        >
          <div
            className="accordion-header d-flex collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#performance-target"
          >
            <span className="accordion-icon">
              <span className="svg-icon svg-icon-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                      x="7.5"
                      y="7.5"
                      width={2}
                      height={9}
                      rx={1}
                    />
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                    />
                  </g>
                </svg>
              </span>
            </span>
            <h3 className="fs-4 text-dark fw-bold mb-0 ms-2 text-capitalize fw-bolder">
              Performance
            </h3>
          </div>
          <div
            id="performance-target"
            className="fs-6 collapse ps-7 pt-2"
            data-bs-parent="#performance-accordian"
          >
            <ul className="list-unstyled d-flex flex-wrap">
              <PerCheckbox check={check} data={data} />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Metrics;
