import React from "react";
import { Link } from "react-router-dom";
function Header() {
  return (
    <>
      <div id="kt_header" className="header align-items-stretch shadow-sm">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary"
              id="kt_aside_mobile_toggle"
            >
              <span className="svg-icon svg-icon-2x mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <rect
                      fill="#000000"
                      x={4}
                      y={5}
                      width={16}
                      height={3}
                      rx="1.5"
                    />
                    <path
                      d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>

          <div className="d-flex align-items-justify-content-center flex-grow-1 flex-lg-grow-0">
            <Link to="/" className="d-lg-none text-dark">
              <svg xmlns="http://www.w3.org/2000/svg" width={120} height={40}>
                <svg
                  viewBox="0 0 562 161"
                  className="logo__logo--1xeTw logo__logo_light--1gLXh"
                >
                  <g fill="#222" fillRule="evenodd">
                    <path
                      fill="currentColor"
                      d="M553.464 150.256c1.092 0 1.794-.703 1.794-1.872 0-1.144-.702-1.794-1.794-1.794h-1.899v3.666h1.899zm-3.355-4.888h3.615c1.794 0 3.042 1.196 3.042 3.016 0 1.508-.962 2.444-1.898 2.678v.052s.104.13.338.572l1.95 3.823h-1.612l-2.028-4.109h-1.951v4.109h-1.456v-10.141zm3.069 13.13c4.42 0 7.801-3.51 7.801-8.06 0-4.498-3.381-8.034-7.801-8.034-4.317 0-7.645 3.536-7.645 8.034 0 4.55 3.328 8.06 7.645 8.06zm0-17.498c5.174 0 9.308 4.185 9.308 9.438 0 5.304-4.134 9.464-9.308 9.464-5.071 0-9.178-4.16-9.178-9.464 0-5.253 4.107-9.438 9.178-9.438zm-508.491-1.693c14.603 0 25.066-15.774 25.066-29.36v-2.625h-4.794c-14.17 0-37.058 1.97-37.058 17.526 0 7.448 5.45 14.459 16.786 14.459zM65.831 88.26h3.705v-1.534c0-14.682-9.155-19.718-21.364-19.718-15.91 0-30.515 10.733-30.515 10.733L7.195 57.804S24.414 44 50.79 44C80 44 97 60.214 97 88.477v69.893H71.498v-9.204c0-4.381.437-7.885.437-7.885h-.437c.218 0-8.501 19.719-33.786 19.719C17.875 161 0 148.511 0 126.163 0 90.67 47.739 88.26 65.83 88.26zm122.468-19.62h-37.856V158h-27.701V68.64H109V46.57h13.742v-3.275C122.742 6.809 152.623 2 168.11 2c5.67 0 9.378.658 9.378.658v23.597s-2.4-.44-5.671-.44c-7.852 0-21.374 1.97-21.374 18.134v2.622H216V158h-27.701V68.64M244 46.62h26.687v12.89c0 4.144-.438 7.64-.438 7.64h.438C275.5 57.76 287.75 44 310.282 44 335.001 44 349 56.889 349 86.37V158h-27.562V92.048c0-13.541-3.502-22.711-17.72-22.711-14.874 0-25.812 9.387-29.968 22.711-1.532 4.585-1.968 9.609-1.968 14.848V158H244V46.62M375 158h28V47h-28zm58.367-86.777H419V49.141h15.02V17h26.989v32.141h25.25v22.082h-25.25v43.294c0 18.366 14.366 20.99 21.985 20.99 3.046 0 5.006-.435 5.006-.435v24.27s-3.266.658-8.488.658c-15.455 0-46.145-4.59-46.145-41.983V71.223M507 158h28V47h-28z"
                    />
                    <path
                      d="M388.727 34C398.09 34 406 26.276 406 17.134 406 7.687 398.251 0 388.727 0 379.505 0 372 7.687 372 17.133 372 26.275 379.66 34 388.727 34m132 0C530.09 34 538 26.276 538 17.134 538 7.687 530.252 0 520.727 0 511.504 0 504 7.687 504 17.133 504 26.275 511.66 34 520.727 34"
                      fill="#DB364F"
                    ></path>
                  </g>
                </svg>
              </svg>
            </Link>
          </div>

          <div className="d-flex align-items-stretch justify-content-end flex-lg-grow-1">
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-place="true"
                data-kt-place-mode="prepend"
                data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                <div
                  className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                  id="#kt_header_menu"
                  data-kt-menu="true"
                >
                  <div className="menu-item me-lg-1">
                    <a className="menu-link py-3" href="#!">
                      <span className="menu-title d-inline-block me-2">
                        Navneel
                      </span>
                      <span className="svg-icon svg-icon-primary svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                              transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) "
                              x={13}
                              y={6}
                              width={2}
                              height={12}
                              rx={1}
                            />
                            <path
                              d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </div>

                  <div
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start"
                    className="menu-item menu-lg-down-accordion ms-2"
                  >
                    <span className="menu-link py-3">
                      <span className="menu-title">English</span>
                      <span className="menu-arrow" />
                    </span>
                    <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-150px">
                      <div className="menu-item">
                        <a className="menu-link py-3" href="#!">
                          <div className="form-check form-check-custom form-check-solid me-10">
                            <input
                              className="form-check-input h-15px w-15px"
                              name="lang"
                              type="radio"
                              defaultValue
                              id="lang1"
                            />
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor="lang1"
                            >
                              deutsch
                            </label>
                          </div>
                        </a>
                      </div>
                      <div className="menu-item">
                        <a className="menu-link py-3" href="#!">
                          <div className="form-check form-check-custom form-check-solid me-10">
                            <input
                              className="form-check-input h-15px w-15px"
                              name="lang"
                              type="radio"
                              defaultValue
                              id="lang2"
                            />
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor="lang2"
                            >
                              english
                            </label>
                          </div>
                        </a>
                      </div>
                      <div className="menu-item">
                        <a className="menu-link py-3" href="#!">
                          <div className="form-check form-check-custom form-check-solid me-10">
                            <input
                              className="form-check-input h-15px w-15px"
                              name="lang"
                              type="radio"
                              defaultValue
                              id="lang3"
                            />
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor="lang3"
                            >
                              espanol
                            </label>
                          </div>
                        </a>
                      </div>
                      <div className="menu-item">
                        <a className="menu-link py-3" href="#!">
                          <div className="form-check form-check-custom form-check-solid me-10">
                            <input
                              className="form-check-input h-15px w-15px"
                              name="lang"
                              type="radio"
                              defaultValue
                              id="lang4"
                            />
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor="lang4"
                            >
                              francais
                            </label>
                          </div>
                        </a>
                      </div>
                      <div className="menu-item">
                        <a className="menu-link py-3" href="#!">
                          <div className="form-check form-check-custom form-check-solid me-10">
                            <input
                              className="form-check-input h-15px w-15px"
                              name="lang"
                              type="radio"
                              defaultValue
                              id="lang5"
                            />
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor="lang5"
                            >
                              portugues
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="menu-item mx-2">
                    <a className="p-3 btn-sm btn btn-light-primary" href="#!">
                      <span className="svg-icon svg-icon-primary svg-icon-2 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M11.914857,14.1427403 L14.1188827,11.9387145 C14.7276032,11.329994 14.8785122,10.4000511 14.4935235,9.63007378 L14.3686433,9.38031323 C13.9836546,8.61033591 14.1345636,7.680393 14.7432841,7.07167248 L17.4760882,4.33886839 C17.6713503,4.14360624 17.9879328,4.14360624 18.183195,4.33886839 C18.2211956,4.37686904 18.2528214,4.42074752 18.2768552,4.46881498 L19.3808309,6.67676638 C20.2253855,8.3658756 19.8943345,10.4059034 18.5589765,11.7412615 L12.560151,17.740087 C11.1066115,19.1936265 8.95659008,19.7011777 7.00646221,19.0511351 L4.5919826,18.2463085 C4.33001094,18.1589846 4.18843095,17.8758246 4.27575484,17.613853 C4.30030124,17.5402138 4.34165566,17.4733009 4.39654309,17.4184135 L7.04781491,14.7671417 C7.65653544,14.1584211 8.58647835,14.0075122 9.35645567,14.3925008 L9.60621621,14.5173811 C10.3761935,14.9023698 11.3061364,14.7514608 11.914857,14.1427403 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </div>

                  <div className="menu-item">
                    <a className="p-3 btn-sm btn btn-light-primary" href="#!">
                      <span className="svg-icon svg-icon-primary svg-icon-2 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx={12}
                              cy={12}
                              r={10}
                            />
                            <path
                              d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
