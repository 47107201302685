import React from "react";
function Grouping({ change, data }) {
  return (
    <>
      <div className="tab-pane fade" id="groupings" role="tabpanel">
        <p className="mb-6 lh-lg">
          Groupings determine how the data is aggregated in the rows of your
          table. Adding more groupings will show more granular data, and
          increase the number of rows displayed.
        </p>
        <h6 className="text-capitalize fw-bolder fs-4">Grouping options</h6>
        <div className="w-100 mt-3">
          <div className="form-group">
            <label className="my-3 fw-bolder fs-6">Queues</label>
            <ul className="list-unstyled list-inline d-md-flex justify-content-between">
              <li className="list-inline-item">
                <div className="form-check form-check-custom form-check-solid mb-4">
                  <input
                    className="form-check-input h-20px w-20px"
                    name="radio1"
                    onChange={change}
                    value="Queues"
                    checked={data.radio1 === "Queues"}
                    type="radio"
                    id="rad1"
                  />
                  <label className="form-check-label" htmlFor="rad1">
                    Queues
                  </label>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="form-check form-check-custom form-check-solid mb-4">
                  <input
                    className="form-check-input h-20px w-20px"
                    name="radio1"
                    onChange={change}
                    value="Queues grouped by channels"
                    checked={data.radio1 === "Queues grouped by channels"}
                    type="radio"
                    id="rad2"
                  />
                  <label className="form-check-label" htmlFor="rad2">
                    Queues grouped by channels
                  </label>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="form-check form-check-custom form-check-solid mb-4">
                  <input
                    className="form-check-input h-20px w-20px"
                    name="radio1"
                    onChange={change}
                    value="Queues grouped by routing profiles"
                    checked={
                      data.radio1 === "Queues grouped by routing profiles"
                    }
                    type="radio"
                    id="rad3"
                  />
                  <label className="form-check-label" htmlFor="rad3">
                    Queues grouped by routing profiles
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Grouping;
