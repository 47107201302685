import React from "react";
import Checkbox from "./Checkbox";
function ConCheckbox({ check, data }) {
  return (
    <>
      <Checkbox
        name="sl1"
        check={check}
        value={data.sl1}
        time="SL 15secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl2"
        check={check}
        value={data.sl2}
        time="SL 20secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl3"
        check={check}
        value={data.sl3}
        time="SL 25secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl4"
        check={check}
        value={data.sl4}
        time="SL 30secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl5"
        check={check}
        value={data.sl5}
        time="SL 45secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl6"
        check={check}
        value={data.sl6}
        time="SL 60secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl7"
        check={check}
        value={data.sl7}
        time="SL 90secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl8"
        check={check}
        value={data.sl8}
        time="SL 120secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl9"
        check={check}
        value={data.sl9}
        time="SL 180secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl10"
        check={check}
        value={data.sl10}
        time="SL 240secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl11"
        check={check}
        value={data.sl11}
        time="SL 300secs"
        cl="col-md-4 col-12 mt-4"
      />
      <Checkbox
        name="sl12"
        check={check}
        value={data.sl12}
        time="SL 600secs"
        cl="col-md-4 col-12 mt-4"
      />
    </>
  );
}

export default ConCheckbox;
