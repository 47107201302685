import React from "react";
function Checkbox({ name, check, value, time, cl }) {
  return (
    <>
      <li className={cl}>
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input h-20px w-20px"
            type="checkbox"
            onChange={check}
            name={name}
            checked={value}
            id={name}
          />
          <label className="form-check-label" htmlFor={name}>
            {time}
          </label>
        </div>
      </li>
    </>
  );
}

export default Checkbox;
