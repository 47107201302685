import React from "react";
function TimeRange({ change, data }) {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="time_range"
        role="tabpanel"
      >
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-check form-check-custom form-check-solid me-10 mb-4">
              <input
                className="form-check-input h-20px w-20px"
                name="timeRadio"
                type="radio"
                value="Trailing windows for time"
                checked={data.timeRadio === "Trailing windows for time"}
                onChange={change}
                id="flexRadio30"
              />
              <label
                className="form-check-label text-capitalize"
                htmlFor="flexRadio30"
              >
                Trailing windows for time
              </label>
            </div>
            <div className="form-group d-flex align-items-center ps-8">
              <label
                htmlFor="exampleSelect1"
                className="w-auto me-3 text-capitalize fw-bolder"
              >
                the previous hour
              </label>
              <select
                className="form-select mw-75px btn-sm"
                id="exampleSelect1"
              >
                <option>0.5</option>
                <option>1</option>
                <option>2</option>
                <option>4</option>
                <option>8</option>
                <option>12</option>
                <option>24</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-check form-check-custom form-check-solid me-10 mb-4">
              <input
                className="form-check-input h-20px w-20px"
                name="timeRadio"
                type="radio"
                value="midnight to now"
                checked={data.timeRadio === "midnight to now"}
                onChange={change}
                id="flexRadio"
              />
              <label
                className="form-check-label text-capitalize"
                htmlFor="flexRadio"
              >
                midnight to now
              </label>
            </div>
            <div className="form-group d-flex align-items-center ps-8">
              <label
                htmlFor="exampleSelect1"
                className="min-w-75px w-auto me-3 text-capitalize fw-bolder"
              >
                time zone
              </label>
              <select
                className="form-select form-select-sm mw-150px btn-sm"
                data-control="select2"
                data-placeholder="Select an option"
              >
                <option />
                <option>Africa/Accra</option>
                <option>Africa/Bissau</option>
                <option>America/Cordoba</option>
                <option>Australia/North</option>
                <option>GMT+11</option>
                <option>Europe/Belfast</option>
                <option>Europe/Kiev</option>
                <option>Europe/San Marino</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeRange;
