import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import data1 from "../add1.json";
import data2 from "../data.json";
//import Card from "./Card";
import Filters from "./modal/Filters";
import Grouping from "./modal/Grouping";
import Metrics from "./modal/Metrics";
import TimeRange from "./modal/TimeRange";
import Box from "./Box";
import Datatable from "../Datatable";
function Dashboard() {
  const [data, setData] = useState(data1);
  const [bool, setBool] = useState(true);
  const [choose, setChoose] = useState(false);
  var d = Date(Date.now());
  let a = d.toString().split("G")[0];
  const [date, setDate] = useState(a);

  useEffect(() => {
    setInterval(function () {
      setBool(false);
      // console.log(bool)
    }, 5000);
  }, []);

  useEffect(() => {
    setInterval(function () {
      var d = Date(Date.now());
      let a = d.toString();
      let a1 = a.split("G")[0];
      // console.log(bool)
      setDate(a1);
      setBool(true);
    }, 10000);

    //return () => clearInterval(intervalId);
  }, []);
  const [state, setState] = useState({
    filter_primary_groupings: "",
    include: "",
    check1: "",
    check2: "",
    check3: "",
    check4: "",
    check5: "",
    check6: "",
    check7: "",
    contact1: "",
    contact2: "",
    length_of_time: "",
    unit: "",
    sl1: "",
    sl2: "",
    sl3: "",
    sl4: "",
    sl5: "",
    sl6: "",
    sl7: "",
    sl8: "",
    sl9: "",
    sl10: "",
    sl11: "",
    sl12: "",
    perform1: "",
    perform2: "",
    perform3: "",
    perform4: "",
    perform5: "",
    perform6: "",
    perform7: "",
    perform8: "",
    perform9: "",
    perform10: "",
    perform11: "",
    perform12: "",
    perform13: "",
    perform14: "",
    perform15: "",
    perform16: "",
    perform17: "",
    perform18: "",
    perform19: "",
    perform20: "",
    perform21: "",
    perform22: "",
    perform23: "",
    perform24: "",
    perform25: "",
    perform26: "",
    perform27: "",
    perform28: "",
    radio1: "",
    timeRadio: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
  };

  const handleRadio = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid pt-20"
            id="kt_wrapper"
          >
            <Header />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="toolbar d-none" id="kt_toolbar">
                <div
                  id="kt_toolbar_container"
                  className="container-fluid d-flex flex-stack"
                ></div>
              </div>
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid">
                  <div className="row g-5 gx-xxl-8">
                    <Box />
                    <div className="col-auto mt-8 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between w-100">
                      <div className="card-title align-items-start m-0">
                        <span className="card-label fw-bolder fs-2 mb-1 text-dark text-capitalize">
                          Real-time metrics
                        </span>
                      </div>
                      <div className="d-flex align-items-center py-md-1 pt-3 w-md-auto w-100">
                        <p className="m-0">Last Update: {date ? date : ""}</p>
                        <button className="btn btn-light-primary btn-sm px-3 mx-3 py-2">
                          <span className="svg-icon svg-icon-primary svg-icon-2 m-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M8,6 L10,6 C10.5522847,6 11,6.44771525 11,7 L11,17 C11,17.5522847 10.5522847,18 10,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M14,6 L16,6 C16.5522847,6 17,6.44771525 17,7 L17,17 C17,17.5522847 16.5522847,18 16,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,7 C13,6.44771525 13.4477153,6 14,6 Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                          </span>
                        </button>
                        {/* {bool ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : ( */}
                        <button className="btn btn-light-primary btn-sm px-3 py-2">
                          <span className="svg-icon svg-icon-primary svg-icon-2 m-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g>
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                </g>
                                <path
                                  d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                  transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                                />
                              </g>
                            </svg>
                          </span>
                        </button>

                        <div className="btn-group ms-3">
                          <button
                            type="button"
                            className="btn btn-primary py-2 px-4 fw-normal"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split px-4 py-2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="visually-hidden">
                              Toggle Dropdown
                            </span>
                          </button>
                          <ul className="dropdown-menu p-0">
                            <li>
                              <a
                                className="dropdown-item text-capitalize py-3 px-5"
                                href="#!"
                              >
                                clear
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-capitalize py-3 px-5"
                                href="#!"
                              >
                                save as
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-capitalize py-3 px-5"
                                href="#!"
                              >
                                download CSV
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-capitalize py-3 px-5"
                                href="#!"
                              >
                                share report
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <Datatable
                      data1={data}
                      bool={bool}
                      data2={data2}
                      choose={choose}
                    />
                    {/* <Card
                      data1={data}
                      bool={bool}
                      data2={data2}
                      choose={choose}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="settings-modal"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered mw-700px">
                <div className="modal-content">
                  <div className="modal-header py-5" id="settings-modal-header">
                    <h2 className="m-0 text-capitalize">table settings</h2>
                    <div
                      className="btn btn-sm btn-icon btn-active-color-primary"
                      data-bs-dismiss="modal"
                    >
                      <span className="svg-icon svg-icon-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              fill="#000000"
                              x={0}
                              y={7}
                              width={16}
                              height={2}
                              rx={1}
                            />
                            <rect
                              fill="#000000"
                              opacity="0.5"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
                              x={0}
                              y={7}
                              width={16}
                              height={2}
                              rx={1}
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="modal-body py-5 pb-10">
                    <div className="scroll-y me-n7 pe-7 h-325px">
                      <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-9 fs-6">
                        <li className="nav-item">
                          <a
                            className="nav-link active text-capitalize px-4 text-dark"
                            data-bs-toggle="tab"
                            href="#time_range"
                          >
                            time range
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-capitalize px-4 text-dark"
                            data-bs-toggle="tab"
                            href="#groupings"
                          >
                            groupings
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-capitalize px-4 text-dark"
                            data-bs-toggle="tab"
                            href="#filters"
                          >
                            filters
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-capitalize px-4 text-dark"
                            data-bs-toggle="tab"
                            href="#metrics"
                          >
                            metrics
                          </a>
                        </li>
                      </ul>
                      <div
                        className="tab-content scroll-y h-250px pe-3"
                        id="myTabContent"
                      >
                        <TimeRange change={handleRadio} data={state} />
                        <Grouping change={handleRadio} data={state} />
                        <Filters change={handleChange} />
                        <Metrics
                          check={handleChecked}
                          data={state}
                          change={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer flex-end">
                    <button
                      type="reset"
                      className="btn btn-white me-3 btn-sm fw-normal"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm fw-normal"
                    >
                      <span
                        className="indicator-label"
                        onClick={handleSubmit}
                        data-bs-dismiss="modal"
                      >
                        Apply
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
