import React from "react";
function Filters({ change }) {
  return (
    <>
      <div className="tab-pane fade" id="filters" role="tabpanel">
        <p className="mb-6 lh-lg">
          The setting will determine the main rows (primary grouping) in the
          table.
        </p>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label
                htmlFor="filter"
                className="w-100 me-2 text-capitalize fw-bolder mb-2"
              >
                Filter primary groupings by
              </label>
              <select
                className="form-select mw-150px btn-sm"
                id="filter"
                name="filter_primary_groupings"
                onChange={change}
              >
                <option>Queues</option>
                <option>Routing Profiles</option>
                <option>Channels</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label
                htmlFor="include"
                className="w-100 me-2 text-capitalize fw-bolder mb-2"
              >
                include
              </label>
              <select
                className="form-select btn-sm customSelect-bg"
                onChange={change}
                name="include"
                data-placeholder="Select an option"
                data-allow-clear="true"
              >
                <option />
                <option>Another Option</option>
                <option>Basic Queue</option>
                <option>Another Option 2</option>
                <option>Another Option 3</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
