import React,{useState} from "react";
import Setting from './Setting'
import data from "../d.json";
function Box() {
  const[val,setVal]=useState(21)
  const[val1,setVal1]=useState(80)
  const[val2,setVal2]=useState(10)
  const[val3,setVal3]=useState(20)
  const[fourth,setFourth]=useState({
    channel:"Task",
    column_name:"Abandoned"
  })
  const[third,setThird]=useState({
   channel:"Voice",
   column_name:"Available"
 })
  const[second,setSecond]=useState({
   channel:"Task",
   column_name:"API contacts handled"
 })
  const[first,setFirst]=useState({
    channel:"Chat",
    column_name:"ACW"
  })
  const change=(e)=>{
   console.log(e.target.value)
   const{name,value}=e.target;
   setFirst({...first,[name]:value})
 }

 const change1=(e)=>{
  const{name,value}=e.target;
  setSecond({...second,[name]:value})
}
const change2=(e)=>{
  const{name,value}=e.target;
  setThird({...third,[name]:value})
}

const change3=(e)=>{
  const{name,value}=e.target;
  setFourth({...fourth,[name]:value})
}
const handleSubmit=()=>{
 data.map((u,i)=>
   {Object.entries(u.Channels).map(function (keyName,keyIndex) 
     {
      if(keyName[0]===`${first.channel}`)
      {
        let c=keyName[1]
        for(var key in c) {
          if(key=== `${first.column_name}`)
          {
            setVal(c[key])
            return;
          }
        }} })}  
                          
     )
}

const handleSubmit1=()=>{
 data.map((u,i)=>
   {Object.entries(u.Channels)
     .map(function (keyName,keyIndex) 
     {
      if(keyName[0]===`${second.channel}`)
      {
        let c=keyName[1]
        for(var key in c) {
          if(key=== `${second.column_name}`)
          {
            setVal1(c[key])
          }
        }} })}                         
     )
}



const handleSubmit2=()=>{
 data.map((u,i)=>
   {Object.entries(u.Channels)
     .map(function (keyName,keyIndex) 
     {
      if(keyName[0]===`${third.channel}`)
      {
        let c=keyName[1]
        for(var key in c) {
          if(key=== `${third.column_name}`)
          {
            setVal2(c[key])
          }
        }} })}                         
     )
}

const handleSubmit3=()=>{
 data.map((u,i)=>
   {Object.entries(u.Channels)
     .map(function (keyName,keyIndex) 
     {
      if(keyName[0]===`${fourth.channel}`)
      {
        let c=keyName[1]
        for(var key in c) {
          if(key=== `${fourth.column_name}`)
          {
            setVal3(c[key])
          }
        }} })}                         
     )
}

return (
  <>
{/*begin::top boxes - 1 */}
<div className="col-lg-3 col-md-6 col-12">
<div className="card shadow-sm h-100">
<div className="card-header pt-6 px-6 pb-0 border-0 min-h-unset">

<span className="svg-icon svg-icon-2 svg-icon-success badge-light-success rounded p-3">
<svg
xmlns="http://www.w3.org/2000/svg"
xmlnsXlink="http://www.w3.org/1999/xlink"
width="24px"
height="24px"
viewBox="0 0 24 24"
version="1.1"
>
<g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
<rect x={0} y={0} width={24} height={24} />
<path
d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,15 L6.27924078,15 L6.82339262,16.6324555 C7.09562072,17.4491398 7.8598984,18 8.72075922,18 L15.381966,18 C16.1395101,18 16.8320364,17.5719952 17.1708204,16.8944272 L18.118034,15 L22,15 Z"
fill="#000000"
/>
<path
d="M2.5625,13 L5.92654389,7.01947752 C6.2807805,6.38972356 6.94714834,6 7.66969497,6 L16.330305,6 C17.0528517,6 17.7192195,6.38972356 18.0734561,7.01947752 L21.4375,13 L18.118034,13 C17.3604899,13 16.6679636,13.4280048 16.3291796,14.1055728 L15.381966,16 L8.72075922,16 L8.17660738,14.3675445 C7.90437928,13.5508602 7.1401016,13 6.27924078,13 L2.5625,13 Z"
fill="#000000"
opacity="0.3"
/>
</g>
</svg>
</span>
<Setting change={change} handleSubmit={handleSubmit} channel={first.channel} column_name={first.column_name}/>
</div>
<div className="card-body p-6 d-flex flex-column ">
<h2 className="fs-1 text-dark fw-bolder mt-0 mb-2">{val}</h2>
<h4 className="text-dark fw-normal lh-base my-0 fs-6 mb-4">
<strong>{first.column_name}</strong>
</h4>
<h5 className="fw-lighter fs-7 m-0">{first.channel}</h5>

</div>
</div>
</div>
{/*end::top boxes - 1 */}
{/*begin::top boxes - 2 */}
<div className="col-lg-3 col-md-6 col-12">
<div className="card shadow-sm h-100">
<div className="card-header pt-6 px-6 pb-0 border-0 min-h-unset">
<span className="svg-icon svg-icon-danger svg-icon-2 badge-light-danger rounded p-3">
<svg
xmlns="http://www.w3.org/2000/svg"
xmlnsXlink="http://www.w3.org/1999/xlink"
width="24px"
height="24px"
viewBox="0 0 24 24"
version="1.1"
>
<g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
<polygon points="0 0 24 0 24 24 0 24" />
<path
d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
fill="#000000"
fillRule="nonzero"
opacity="0.3"
transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
/>
<path
d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
fill="#000000"
fillRule="nonzero"
transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
/>
</g>
</svg>
</span>
<Setting change={change1} handleSubmit={handleSubmit1} channel={second.channel} column_name={second.column_name}/>
</div>
<div className="card-body p-6 d-flex flex-column ">
<h2 className="fs-1 text-dark fw-bolder mt-0 mb-2">{val1}</h2>
<h4 className="text-dark fw-normal lh-base my-0 fs-6 mb-4">
<strong>{second.column_name}</strong>
</h4>
<h5 className="fw-lighter fs-7 m-0">{second.channel}</h5>
      </div>
      </div>
      </div>
    {/*end::top boxes - 2 */}
  {/*begin::top boxes - 3 */}
  <div className="col-lg-3 col-md-6 col-12">
  <div className="card shadow-sm h-100">
  <div className="card-header pt-6 px-6 pb-0 border-0 min-h-unset">
  <span className="svg-icon svg-icon-primary svg-icon-2 badge-light-primary rounded p-3">
  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  >
  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  <polygon points="0 0 24 0 24 24 0 24" />
  <path
  d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
  fill="#000000"
  fillRule="nonzero"
  opacity="0.3"
  />
  <path
  d="M10.5857864,13 L9.17157288,11.5857864 C8.78104858,11.1952621 8.78104858,10.5620972 9.17157288,10.1715729 C9.56209717,9.78104858 10.1952621,9.78104858 10.5857864,10.1715729 L12,11.5857864 L13.4142136,10.1715729 C13.8047379,9.78104858 14.4379028,9.78104858 14.8284271,10.1715729 C15.2189514,10.5620972 15.2189514,11.1952621 14.8284271,11.5857864 L13.4142136,13 L14.8284271,14.4142136 C15.2189514,14.8047379 15.2189514,15.4379028 14.8284271,15.8284271 C14.4379028,16.2189514 13.8047379,16.2189514 13.4142136,15.8284271 L12,14.4142136 L10.5857864,15.8284271 C10.1952621,16.2189514 9.56209717,16.2189514 9.17157288,15.8284271 C8.78104858,15.4379028 8.78104858,14.8047379 9.17157288,14.4142136 L10.5857864,13 Z"
  fill="#000000"
  />
  </g>
  </svg>
  </span>
  <Setting change={change2} handleSubmit={handleSubmit2} channel={third.channel} column_name={third.column_name}/>
  </div>
  <div className="card-body p-6 d-flex flex-column ">
  <h2 className="fs-1 text-dark fw-bolder mt-0 mb-2">{val2}</h2>
  <h4 className="text-dark fw-normal lh-base my-0 fs-6 mb-4">
  <strong>{third.column_name}</strong>
  </h4>
  <h5 className="fw-lighter fs-7 m-0">{third.channel}</h5>
      </div>
      </div>
      </div>
    {/*end::top boxes - 3 */}
  {/*begin::top boxes - 4 */}
  <div className="col-lg-3 col-md-6 col-12">
  <div className="card shadow-sm h-100">
  <div className="card-header pt-6 px-6 pb-0 border-0 min-h-unset">
  <span className="svg-icon svg-icon-info svg-icon-2 badge-light-info rounded p-3">
  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  >
  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  <rect x={0} y={0} width={24} height={24} />
  <path
  d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
  fill="#000000"
  opacity="0.3"
  />
  <path
  d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
  fill="#000000"
  />
  </g>
  </svg>
  </span>
  <Setting change={change3} handleSubmit={handleSubmit3} channel={fourth.channel} column_name={fourth.column_name}/>
  </div>
  <div className="card-body p-6 d-flex flex-column ">
  <h2 className="fs-1 text-dark fw-bolder mt-0 mb-2">{val3}</h2>
  <h4 className="text-dark fw-normal lh-base my-0 fs-6 mb-4">
  <strong>{fourth.column_name}</strong>
  </h4>
  <h5 className="fw-lighter fs-7 m-0">{fourth.channel}</h5>
      </div>
      </div>
      </div>
    {/*end::top boxes - 4 */}
    </>
    );
}

export default Box;
