import React, { useEffect, useState } from "react";
import "./DataTable.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-fixedcolumns";
import "datatables.net-buttons";
import "datatables.net-buttons-dt";
//import data from "./table.json";
import { AnimateOnChange } from "react-animation";
// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5.min";
import "datatables.net-rowgroup";
import "datatables.net-rowgroup-dt";
//import german from './german.json'
import CardButton from "./dashboard/CardButton";
function Datatable({ data1, bool, data2, choose }) {
  const [d, setD] = useState(data2);

  const addCard=()=>{

  }

  useEffect(() => {
    if (bool) {
      setD(data2);
    } else {
      setD(data1);
    }
  },[bool]);
  useEffect(() => {
    $(document).ready(function () {
      $("table.display").DataTable({
        scrollX: true,
       
       // stateSave: true,
        scrollCollapse: true,
        scrollY:        "300px",
        fixedColumns: {
          leftColumns: 2,
        },
        language: { search:"", searchPlaceholder: "Search "},
       //language:german,
        dom: "lBfrtip",
        columnDefs: [
        {
          targets: [0,1],
          className: 'noVis'
        }
        ],
        buttons: [
        {
         extend: 'colvis',
         columns: ':not(.noVis)',
         prefixButtons: [
         {
          extend: "colvisRestore",
          text: "Restore",
        }
        ],
        
       
      },
      // {
      //           extend: 'collection',
      //           text: 'Save',
      //           buttons: [
      //               'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
      //           ]
      //       }
       
        ],
      });
    });

  }, []);


  return (
    <>
    {d.map((u, i) => (
      <div key={i}>
      <div className="card mb-3 px-0">
      <div className="card-header border-0 pt-5">
      <CardButton
      queueName={u["Queue Name"]}
                //col={col}
                // change={change}
                k={i}
                />
                </div>

                <div className="card-body mb-5 py-3">
                <table
                id={`example${i}`}
                className="display nowrap text-center"
                data-ordering="false"
                >
                <thead>
                <tr className="fw-bolder text-muted bg-light">
                <th className="ps-4 text-dark fs-6 text-uppercase align-middle rounded-start">
                Queue Name
                </th>

                <th className="ps-4 text-dark fs-6 text-uppercase align-middle">
                Channel
                </th>

                <th className="ps-4 align-middle head">ACW</th>

                <th className="align-middle head">AHT</th>
                <th className="align-middle head">
                API contacts handled
                </th>
                <th className="align-middle head">Abandoned</th>
                <th className="align-middle head">Active</th>
                <th className="align-middle head">
                After contact work time
                </th>
                <th className="align-middle head">
                Agent hung up
                </th>
                <th className="align-middle head">
                Agents non-response
                </th>
                <th className="align-middle head">
                Availability
                </th>
                <th className="align-middle head">Available</th>
                <th className="align-middle head">
                Avg API connecting time
                </th>
                <th className="align-middle head">
                Avg abandon time
                </th>
                <th className="align-middle head">
                Avg callback connecting time
                </th>
                <th className="align-middle head">
                Avg hold time
                </th>
                <th className="align-middle head">
                Avg incoming connecting time
                </th>
                <th className="align-middle head">
                Avg interaction and hold time
                </th>
                <th className="align-middle head">
                Avg interaction time
                </th>
                <th className="align-middle head">
                Avg outbound connecting time
                </th>
                <th className="align-middle head">
                Avg queue answer time
                </th>
                <th className="align-middle head">
                Callback contacts handled
                </th>
                <th className="align-middle head">
                Contacts consulted
                </th>
                <th className="align-middle head">Error</th>
                <th className="align-middle head">Handled</th>
                <th className="align-middle head">Handled in</th>
                <th className="align-middle head">Handled out</th>
                <th className="align-middle head">
                Hold abandons
                </th>
                <th className="align-middle head">In queue</th>
                <th className="align-middle head">Max Queued</th>
                <th className="align-middle head">NPT</th>
                <th className="align-middle head">Occupancy</th>
                <th className="align-middle head">Oldest</th>
                <th className="align-middle head">On contact</th>
                <th className="align-middle head">Online</th>
                <th className="align-middle head">Queued</th>
                <th className="align-middle head">SL 120 secs</th>
                <th className="align-middle head">SL 15 secs</th>
                <th className="align-middle head">SL 180 secs</th>
                <th className="align-middle head">SL 20 secs</th>
                <th className="align-middle head ">
                SL 240 secs
                </th>
                <th className="align-middle head ">SL 25 secs</th>
                <th className="align-middle head">SL 30 secs</th>
                <th className="align-middle head">SL 300 secs</th>
                <th className="align-middle head">SL 45 secs</th>
                <th className="align-middle head">SL 60 secs</th>
                <th className="align-middle head ">
                ASL 600 secs
                </th>
                <th className="align-middle head">SL 90 secs</th>
                <th className="align-middle head">Scheduled</th>
                <th className="align-middle head">Staffed</th>
                <th className="align-middle head">
                Tranfered in
                </th>
                <th className="align-middle head">
                Tranfered in from Queue
                </th>
                <th className="align-middle head">
                Tranfered out
                </th>
                <th className="align-middle head">
                Tranfered out from Queue
                </th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(u.Channels).map(function (keyName, keyIndex) {
                  return (
                    <tr key={keyName}>
                    {keyIndex === 1 ?  
                      <td className="ps-4 text-dark fw-bolder fs-6 bg-white">{u["Queue Name"]}</td>                      
                      : <td className="ps-4 text-dark fw-bolder fs-6 bg-white"></td>
                    } 

                    <td className="ps-4 text-dark fw-bolder mb-1 fs-6 ">
                    {keyName}
                    </td>
                    {Object.values(u.Channels[keyName]).map(function (k, i) {
                      return (
                        <td key={i} className="ps-4 mb-1 text-muted ">
                        <AnimateOnChange  durationOut="500" durationIn="500"  animationIn="popIn" animationOut="popOut">{k}</AnimateOnChange>

                        </td>
                        );
                    })}
                    </tr> 
                    );
                })}
                </tbody>
                </table>

                </div>
                </div>
                </div>
                ))}
                <div className="w-100 p-0">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary py-3 ps-4 dropdown-toggle  px-6 py-2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            New Table
          </button>
          <ul className="dropdown-menu p-0">
            <li>
              <button
                className="dropdown-item text-capitalize py-3 px-5"
                onClick={() => {
                  addCard("support");
                }}
              >
                support
              </button>
            </li>
            <li>
              <button
                className="dropdown-item text-capitalize py-3 px-5"
                // href="#!"
                onClick={() => {
                  addCard("sales");
                }}
              >
                sales
              </button>
            </li>
            <li>
              <button
                className="dropdown-item text-capitalize py-3 px-5"
                onClick={() => {
                  addCard("test queue 2");
                }}
              >
                test queue 2
              </button>
            </li>
            <li>
              <button
                className="dropdown-item text-capitalize py-3 px-5"
                onClick={() => {
                  addCard("service");
                }}
              >
                service
              </button>
            </li>
            <li>
              <button
                className="dropdown-item text-capitalize py-3 px-5"
                onClick={() => {
                  addCard("test queue 1");
                }}
              >
                test queue 1
              </button>
            </li>
          </ul>
        </div>
      </div>

</>
);
}

export default Datatable;
