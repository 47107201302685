import Dashboard from "./dashboard/Dashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Dashboard} exact></Route>
        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  );
}

export default App;
