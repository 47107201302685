import React from "react";
import Checkbox from './Checkbox'
function PerCheckbox({ check, data }) {
  return (
    <>
      <Checkbox
        name="perform1"
        check={check}
        value={data.perform1}
        time="Abandoned"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform2"
        check={check}
        value={data.perform2}
        time="Agent hung up"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform3"
        check={check}
        value={data.perform3}
        time="Agent non-response"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform4"
        check={check}
        value={data.perform4}
        time="AHT"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform5"
        check={check}
        value={data.perform5}
        time="API contacts handled"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform6"
        check={check}
        value={data.perform6}
        time="Avg abandon time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform7"
        check={check}
        value={data.perform7}
        time="Avg API connecting time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform8"
        check={check}
        value={data.perform8}
        time="Avg callback connecting time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform9"
        check={check}
        value={data.perform9}
        time="Avg hold time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform10"
        check={check}
        value={data.perform10}
        time="Avg incoming connecting time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform11"
        check={check}
        value={data.perform11}
        time="Avg interaction and hold time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform12"
        check={check}
        value={data.perform12}
        time="Avg interaction time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform13"
        check={check}
        value={data.perform13}
        time="Avg outbound connecting time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform14"
        check={check}
        value={data.perform14}
        time="Avg queue answer time"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform15"
        check={check}
        value={data.perform15}
        time="Callback contacts handled"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform16"
        check={check}
        value={data.perform16}
        time="Handled"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform17"
        check={check}
        value={data.perform17}
        time="Handled in"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform18"
        check={check}
        value={data.perform18}
        time="Handled out"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform19"
        check={check}
        value={data.perform19}
        time="Hold abandons"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform20"
        check={check}
        value={data.perform20}
        time="In queue"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform21"
        check={check}
        value={data.perform21}
        time="Max Queued"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform22"
        check={check}
        value={data.perform22}
        time="Oldest"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform23"
        check={check}
        value={data.perform23}
        time="Queued"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform24"
        check={check}
        value={data.perform24}
        time="Scheduled"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform25"
        check={check}
        value={data.perform25}
        time="Transferred in"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform26"
        check={check}
        value={data.perform26}
        time="Transferred in from queue"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform27"
        check={check}
        value={data.perform27}
        time="Transferred out"
        cl="col-md-6 col-12 mt-4"
      />
      <Checkbox
        name="perform28"
        check={check}
        value={data.perform28}
        time="Transferred out from queue"
        cl="col-md-6 col-12 mt-4"
      />
    </>
  );
}

export default PerCheckbox;
