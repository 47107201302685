import React from "react";
import { Link, NavLink } from "react-router-dom";
function Sidebar() {
  return (
    <>
      <div
        id="kt_aside"
        className="aside aside-dark aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        <div className="aside-logo flex-column-auto" id="kt_aside_logo">
          <Link to="/" className="logo_aside">
            <svg xmlns="http://www.w3.org/2000/svg" width={150} height={40}>
              <svg
                viewBox="0 0 562 161"
                className="logo__logo--1xeTw logo__logo_light--1gLXh"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#fff"
                    d="M553.464 150.256c1.092 0 1.794-.703 1.794-1.872 0-1.144-.702-1.794-1.794-1.794h-1.899v3.666h1.899zm-3.355-4.888h3.615c1.794 0 3.042 1.196 3.042 3.016 0 1.508-.962 2.444-1.898 2.678v.052s.104.13.338.572l1.95 3.823h-1.612l-2.028-4.109h-1.951v4.109h-1.456v-10.141zm3.069 13.13c4.42 0 7.801-3.51 7.801-8.06 0-4.498-3.381-8.034-7.801-8.034-4.317 0-7.645 3.536-7.645 8.034 0 4.55 3.328 8.06 7.645 8.06zm0-17.498c5.174 0 9.308 4.185 9.308 9.438 0 5.304-4.134 9.464-9.308 9.464-5.071 0-9.178-4.16-9.178-9.464 0-5.253 4.107-9.438 9.178-9.438zm-508.491-1.693c14.603 0 25.066-15.774 25.066-29.36v-2.625h-4.794c-14.17 0-37.058 1.97-37.058 17.526 0 7.448 5.45 14.459 16.786 14.459zM65.831 88.26h3.705v-1.534c0-14.682-9.155-19.718-21.364-19.718-15.91 0-30.515 10.733-30.515 10.733L7.195 57.804S24.414 44 50.79 44C80 44 97 60.214 97 88.477v69.893H71.498v-9.204c0-4.381.437-7.885.437-7.885h-.437c.218 0-8.501 19.719-33.786 19.719C17.875 161 0 148.511 0 126.163 0 90.67 47.739 88.26 65.83 88.26zm122.468-19.62h-37.856V158h-27.701V68.64H109V46.57h13.742v-3.275C122.742 6.809 152.623 2 168.11 2c5.67 0 9.378.658 9.378.658v23.597s-2.4-.44-5.671-.44c-7.852 0-21.374 1.97-21.374 18.134v2.622H216V158h-27.701V68.64M244 46.62h26.687v12.89c0 4.144-.438 7.64-.438 7.64h.438C275.5 57.76 287.75 44 310.282 44 335.001 44 349 56.889 349 86.37V158h-27.562V92.048c0-13.541-3.502-22.711-17.72-22.711-14.874 0-25.812 9.387-29.968 22.711-1.532 4.585-1.968 9.609-1.968 14.848V158H244V46.62M375 158h28V47h-28zm58.367-86.777H419V49.141h15.02V17h26.989v32.141h25.25v22.082h-25.25v43.294c0 18.366 14.366 20.99 21.985 20.99 3.046 0 5.006-.435 5.006-.435v24.27s-3.266.658-8.488.658c-15.455 0-46.145-4.59-46.145-41.983V71.223M507 158h28V47h-28z"
                  ></path>
                  <path
                    d="M388.727 34C398.09 34 406 26.276 406 17.134 406 7.687 398.251 0 388.727 0 379.505 0 372 7.687 372 17.133 372 26.275 379.66 34 388.727 34m132 0C530.09 34 538 26.276 538 17.134 538 7.687 530.252 0 520.727 0 511.504 0 504 7.687 504 17.133 504 26.275 511.66 34 520.727 34"
                    fill="#DB364F"
                  />
                </g>
              </svg>
            </svg>
          </Link>

          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <span className="svg-icon svg-icon-1 rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path
                    d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
                  />
                  <path
                    d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.5"
                    transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div className="aside-menu flex-column-fluid">
          <div
            className="hover-scroll-overlay-y my-5 my-lg-5"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset={0}
          >
            <div
              className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
              id="#kt_aside_menu"
              data-kt-menu="true"
            >
              <div className="menu-item">
                <NavLink className="menu-link" to="/">
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <path
                          d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                          fill="#000000"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">Dashboard</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="aside-footer flex-column-auto" id="kt_aside_footer" />
      </div>
    </>
  );
}

export default Sidebar;
